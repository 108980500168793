import { BLCommonFields as DefaultBLCommonFields, MBL_MAPPING_TO_HBL } from '@/common/constants';
import { HBL, Shipment, ShipmentType } from '@/common/type';
import { FormInstance } from 'antd';
import { floor, forEach, get, pick, map } from 'lodash';
import { convertUnit } from '@/common/utils';

export const ContactFields = [
  'carrier',
  'transit_carrier',
  'agent',
  'coloader',
  'cy_location',
  'cfs_location',
  'container_return_location',
  'shipper',
  'consignee',
  'notify',
  'additional_notify',
  'customer',
  'bill_to',
  'trucker',
  'cycfs_location',
];

export function getRoutePath({
  ext_id,
  type,
  hbl,
  tab,
}: {
  ext_id?: string;
  type: ShipmentType;
  hbl?: string;
  tab?: string;
}) {
  return `/shipments/${type}/${ext_id || 'new'}${
    tab && ['accounting'].includes(tab) ? `/${tab}` : ''
  }${hbl ? `?hbl=${hbl}` : ''}`;
}

export function getValuesByKey(record: Shipment, key: string, subKey?: string) {
  const values = [];
  if (get(record, `ombl.${key}${subKey ? `.${subKey}` : ''}`)) {
    values.push(get(record, `ombl.${key}${subKey ? `.${subKey}` : ''}`));
  }
  for (const hbl of record.hbls || ([] as HBL[])) {
    const value = get(hbl, `${key}${subKey ? `.${subKey}` : ''}`);
    if (value && !values.includes(value)) {
      values.push(value);
    }
  }
  return values;
}

export function renderCommaSeparated(record: Shipment, key: string, subKey?: string) {
  const values = getValuesByKey(record, key, subKey);
  return values.length ? values.join(', ') : '-';
}

export function findHBLByIDOrExtID(hbls: HBL[], id: string) {
  return id.indexOf('-') > 0
    ? hbls.find(hbl => hbl.ext_id === id)
    : hbls.find(hbl => `${hbl.id}` === id);
}

export function getInitHblValuesFromMblForm(
  form: FormInstance,
  BLCommonFields = DefaultBLCommonFields,
) {
  const mblValues = pick(
    form.getFieldsValue(),
    BLCommonFields.concat(Object.keys(MBL_MAPPING_TO_HBL)),
  );
  forEach(mblValues, (value, key) => {
    const newKey = MBL_MAPPING_TO_HBL?.[key];
    if (newKey) {
      mblValues[newKey] = value;
      key = newKey;
    }
    if (ContactFields.includes(key) && value) {
      mblValues[key] = { id: `${value}` };
    } else if (['operator', 'sales'].includes(key) && value) {
      mblValues[key] = { id: value };
    }
  });
  const containers = map(
    form.getFieldValue('containers'),
    ({ id, mbl, ext_id, updated_at, ...c }) => ({
      ...c,
      mbl_container: id,
      ext_id: `new_${Math.floor(Math.random() * 1000000)}`,
    }),
  );
  const fieldList = [
    'volumeUnit',
    'weightUnit',
    'package',
    'commodity_info',
    'mark',
    'soc_address',
    'arrival_notice_remark',
    'delivery_order_remark',
    'pieces',
    'weight',
    'measure',
  ];
  const otherFields = form.getFieldsValue(fieldList) || {};

  return {
    ...mblValues,
    containers,
    ...otherFields,
  };
}

export interface WeightInfo {
  gross_weight?: number;
  gross_weight_unit?: string;
  gross_weight_lb?: number;
  chargeable_weight?: number;
  chargeable_weight_unit?: string;
  chargeable_weight_lb?: number;
  volume_weight?: number;
  volume_weight_unit?: string;
  volume_measure?: number;

  consignee_gross_weight?: number;
  consignee_gross_weight_unit?: string;
  consignee_gross_weight_lb?: number;
  consignee_chargeable_weight?: number;
  consignee_chargeable_weight_unit?: string;
  consignee_chargeable_weight_lb?: number;

  shipper_gross_weight?: number;
  shipper_gross_weight_unit?: string;
  shipper_gross_weight_lb?: number;
  shipper_chargeable_weight?: number;
  shipper_chargeable_weight_unit?: string;
  shipper_chargeable_weight_lb?: number;
}

export interface WeightValues {
  weight?: number;
  weightUnit?: string;
  measure?: number;
  volumeUnit?: string;
  weight_info?: WeightInfo;
  chargeable_weight?: number;
  chargeable_weight_unit?: string;
}

export const updateWeightInfo = (values: WeightValues): Partial<WeightValues> => {
  const weightKg = values.weight
    ? values.weightUnit === 'KG'
      ? values.weight
      : convertUnit(values.weight, 'LB', 'KG')
    : 0;

  const measure = values.measure
    ? values.volumeUnit === 'CBM'
      ? values.measure
      : convertUnit(values.measure, 'CFT', 'CBM')
    : 0;

  const volumeWeight = floor(convertUnit(measure, 'CBM', 'KG'), 3);
  const chargeableWeight = floor(Math.max(weightKg, volumeWeight), 3);

  return {
    chargeable_weight: chargeableWeight,
    chargeable_weight_unit: 'KG',
    weight_info: {
      gross_weight: weightKg,
      gross_weight_unit: 'KG',
      gross_weight_lb: floor(convertUnit(weightKg, 'KG', 'LB'), 3),
      chargeable_weight: chargeableWeight,
      chargeable_weight_unit: 'KG',
      chargeable_weight_lb: floor(convertUnit(chargeableWeight, 'KG', 'LB'), 3),
      volume_weight: volumeWeight,
      volume_weight_unit: 'KG',
      volume_measure: measure,

      consignee_gross_weight: weightKg,
      consignee_gross_weight_unit: 'KG',
      consignee_gross_weight_lb: floor(convertUnit(weightKg, 'KG', 'LB'), 3),
      consignee_chargeable_weight: chargeableWeight,
      consignee_chargeable_weight_unit: 'KG',
      consignee_chargeable_weight_lb: floor(convertUnit(chargeableWeight, 'KG', 'LB'), 3),

      shipper_gross_weight: weightKg,
      shipper_gross_weight_unit: 'KG',
      shipper_gross_weight_lb: floor(convertUnit(weightKg, 'KG', 'LB'), 3),
      shipper_chargeable_weight: chargeableWeight,
      shipper_chargeable_weight_unit: 'KG',
      shipper_chargeable_weight_lb: floor(convertUnit(chargeableWeight, 'KG', 'LB'), 3),
    },
  };
};

export const updateVolumeWeightInfo = (
  volumeWeight: number,
  currentWeightInfo?: WeightInfo,
): Partial<WeightValues> => {
  const measure = convertUnit(volumeWeight, 'KG', 'CBM');

  return {
    measure,
    volumeUnit: 'CBM',
    weight_info: {
      ...currentWeightInfo,
      volume_weight: volumeWeight,
      volume_weight_unit: 'KG',
      volume_measure: measure,

      consignee_chargeable_weight: volumeWeight,
      consignee_chargeable_weight_unit: 'KG',
      consignee_chargeable_weight_lb: floor(convertUnit(volumeWeight, 'KG', 'LB'), 3),

      shipper_chargeable_weight: volumeWeight,
      shipper_chargeable_weight_unit: 'KG',
      shipper_chargeable_weight_lb: floor(convertUnit(volumeWeight, 'KG', 'LB'), 3),
    },
  };
};

export const updateChargeableWeightInfo = (
  chargeableWeight: number,
  currentWeightInfo?: WeightInfo,
): Partial<WeightValues> => {
  return {
    chargeable_weight: chargeableWeight,
    chargeable_weight_unit: 'KG',
    weight_info: {
      ...currentWeightInfo,
      chargeable_weight: chargeableWeight,
      chargeable_weight_unit: 'KG',
      chargeable_weight_lb: floor(convertUnit(chargeableWeight, 'KG', 'LB'), 3),

      consignee_chargeable_weight: chargeableWeight,
      consignee_chargeable_weight_unit: 'KG',
      consignee_chargeable_weight_lb: floor(convertUnit(chargeableWeight, 'KG', 'LB'), 3),

      shipper_chargeable_weight: chargeableWeight,
      shipper_chargeable_weight_unit: 'KG',
      shipper_chargeable_weight_lb: floor(convertUnit(chargeableWeight, 'KG', 'LB'), 3),
    },
  };
};

export const updateGrossWeightInfo = (
  grossWeight: number,
  currentWeightInfo?: WeightInfo,
): Partial<WeightValues> => {
  return {
    weight: grossWeight,
    weightUnit: 'KG',
    weight_info: {
      ...currentWeightInfo,
      gross_weight: grossWeight,
      gross_weight_unit: 'KG',
      gross_weight_lb: floor(convertUnit(grossWeight, 'KG', 'LB'), 3),

      consignee_gross_weight: grossWeight,
      consignee_gross_weight_unit: 'KG',
      consignee_gross_weight_lb: floor(convertUnit(grossWeight, 'KG', 'LB'), 3),

      shipper_gross_weight: grossWeight,
      shipper_gross_weight_unit: 'KG',
      shipper_gross_weight_lb: floor(convertUnit(grossWeight, 'KG', 'LB'), 3),
    },
  };
};
