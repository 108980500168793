// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/runner/work/app/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basic' */'@/layouts/basic'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'@/pages/user/login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/forgot_password",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basic' */'@/layouts/basic'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/forgot_password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__forgetPassword' */'@/pages/user/forgetPassword'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/reset_password",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basic' */'@/layouts/basic'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/reset_password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__resetPassword' */'@/pages/user/resetPassword'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/logout",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basic' */'@/layouts/basic'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/logout",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__logout' */'@/pages/user/logout'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/documents",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__document' */'@/layouts/document'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/documents/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__documents__index' */'@/pages/documents/index'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/pdfs",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__document' */'@/layouts/document'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/pdfs/:category/:dataId/printable",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pdfs__printable' */'@/pages/pdfs/printable'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/pdfs/:category/:dataId",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pdfs__index' */'@/pages/pdfs/index'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__main' */'@/layouts/main'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "routes": [
      {
        "path": "/oauth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__oauth__index' */'@/pages/oauth/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/customer/shipments/:shipment_type/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__customer__shipmentDetails' */'@/pages/shipment/customer/shipmentDetails'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/customer/shipments/ocean_export",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__customer__oceanExportList' */'@/pages/shipment/customer/oceanExportList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/customer/shipments/ocean_import",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__customer__oceanImportList' */'@/pages/shipment/customer/oceanImportList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/customer/shipments/misc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__customer__miscList' */'@/pages/shipment/customer/miscList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/customer/shipments/air_import",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__customer__airImportList' */'@/pages/shipment/customer/airImportList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/customer/shipments/air_export",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__customer__airExportList' */'@/pages/shipment/customer/airExportList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/shipments/:shipment_type/new",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__newShipment' */'@/pages/shipment/newShipment'), loading: LoadingComponent}),
        "access": "new_shipment"
      },
      {
        "path": "/shipments/:shipment_type/duplicate",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__copyShipment' */'@/pages/shipment/copyShipment'), loading: LoadingComponent}),
        "access": "copy_shipment"
      },
      {
        "path": "/bookings/:booking_type(air_export|ocean_export)",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__bookingList' */'@/pages/booking/bookingList'), loading: LoadingComponent}),
        "access": "access_shipment"
      },
      {
        "path": "/bookings/:booking_type(air_export|ocean_export)/new",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__newBooking' */'@/pages/booking/newBooking'), loading: LoadingComponent}),
        "access": "new_shipment"
      },
      {
        "path": "/bookings/:booking_type(air_export|ocean_export)/:ext_id/:tab(accounting|info|documents|status)?",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__detail' */'@/pages/booking/detail'), loading: LoadingComponent}),
        "access": "access_shipment"
      },
      {
        "path": "/shipments/ocean_import/hbls",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanImportList' */'@/pages/shipment/oceanImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_export/hbls",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanExportList' */'@/pages/shipment/oceanExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/all/hbls",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__list' */'@/pages/shipment/list'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_import/hawbs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airImportList' */'@/pages/shipment/airImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_export/hawbs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airExportList' */'@/pages/shipment/airExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_import/mbls",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanImportList' */'@/pages/shipment/oceanImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_export/mbls",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanExportList' */'@/pages/shipment/oceanExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/all/mbls",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__list' */'@/pages/shipment/list'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_import/mawbs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airImportList' */'@/pages/shipment/airImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_export/mawbs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airExportList' */'@/pages/shipment/airExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_import/shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanImportList' */'@/pages/shipment/oceanImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_export/shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanExportList' */'@/pages/shipment/oceanExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_import/shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airImportList' */'@/pages/shipment/airImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_export/shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airExportList' */'@/pages/shipment/airExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_import/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanImportList' */'@/pages/shipment/oceanImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_export/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanExportList' */'@/pages/shipment/oceanExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/:shipment_type/containers",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__containerTracking' */'@/pages/shipment/containerTracking'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/:shipment_type/containers/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__containerTrackingDetail' */'@/pages/shipment/containerTrackingDetail'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_import/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airImportList' */'@/pages/shipment/airImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_export/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airExportList' */'@/pages/shipment/airExportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/all/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__list' */'@/pages/shipment/list'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/misc/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__miscList' */'@/pages/shipment/miscList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/truck/my_shipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__truckList' */'@/pages/shipment/truckList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_import/edi",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanImportList' */'@/pages/shipment/oceanImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/air_import/edi",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airImportList' */'@/pages/shipment/airImportList'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/:shipment_type/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__details' */'@/pages/shipment/details'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/:shipment_type/:ext_id/:tab",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__details' */'@/pages/shipment/details'), loading: LoadingComponent}),
        "access": "access_shipment",
        "exact": true
      },
      {
        "path": "/shipments/ocean_import",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanImportList' */'@/pages/shipment/oceanImportList'), loading: LoadingComponent}),
        "redirect": "/shipments/ocean_import/mbls",
        "exact": true
      },
      {
        "path": "/shipments/ocean_export",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__oceanExportList' */'@/pages/shipment/oceanExportList'), loading: LoadingComponent}),
        "redirect": "/shipments/ocean_export/mbls",
        "exact": true
      },
      {
        "path": "/shipments/misc",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__miscList' */'@/pages/shipment/miscList'), loading: LoadingComponent}),
        "access": "access_shipment"
      },
      {
        "path": "/shipments/truck",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__truckList' */'@/pages/shipment/truckList'), loading: LoadingComponent}),
        "access": "access_shipment"
      },
      {
        "path": "/shipments/air_import",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airImportList' */'@/pages/shipment/airImportList'), loading: LoadingComponent}),
        "redirect": "/shipments/air_import/mawbs",
        "exact": true
      },
      {
        "path": "/shipments/air_export",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipment__airExportList' */'@/pages/shipment/airExportList'), loading: LoadingComponent}),
        "redirect": "/shipments/air_export/mawbs",
        "exact": true
      },
      {
        "path": "/contacts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contact__list' */'@/pages/contact/list'), loading: LoadingComponent}),
        "access": "access_contacts",
        "exact": true
      },
      {
        "path": "/contacts/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contact__detail' */'@/pages/contact/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/contacts/:contactId",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contact__detail' */'@/pages/contact/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin/approval",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__approvalSettings' */'@/pages/admin/approvalSettings'), loading: LoadingComponent}),
        "access": "adminRouteFilter",
        "exact": true
      },
      {
        "path": "/admin/containers",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__containers' */'@/pages/admin/containers'), loading: LoadingComponent}),
        "access": "adminRouteFilter",
        "exact": true
      },
      {
        "path": "/admin/colors",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__colors' */'@/pages/admin/colors'), loading: LoadingComponent}),
        "access": "adminRouteFilter",
        "exact": true
      },
      {
        "path": "/admin/packages",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__packages' */'@/pages/admin/packages'), loading: LoadingComponent}),
        "access": "adminRouteFilter",
        "exact": true
      },
      {
        "path": "/admin/gl_codes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__glCodes' */'@/pages/admin/glCodes'), loading: LoadingComponent}),
        "access": "adminRouteFilter",
        "exact": true
      },
      {
        "path": "/admin/employee",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__employee' */'@/pages/admin/employee'), loading: LoadingComponent}),
        "access": "manage_users",
        "exact": true
      },
      {
        "path": "/admin/tracking_users",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__customerAgent' */'@/pages/admin/customerAgent'), loading: LoadingComponent}),
        "access": "manage_users",
        "exact": true
      },
      {
        "path": "/admin/ams_agents",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__amsAgent' */'@/pages/admin/amsAgent'), loading: LoadingComponent}),
        "access": "manage_users",
        "exact": true
      },
      {
        "path": "/admin/workflows/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__editWorkflow' */'@/pages/admin/editWorkflow'), loading: LoadingComponent}),
        "access": "workflows",
        "exact": true
      },
      {
        "path": "/admin/workflows/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__editWorkflow' */'@/pages/admin/editWorkflow'), loading: LoadingComponent}),
        "access": "workflows",
        "exact": true
      },
      {
        "path": "/admin/workflows",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__workflows' */'@/pages/admin/workflows'), loading: LoadingComponent}),
        "access": "workflows",
        "exact": true
      },
      {
        "path": "/accounting/charge_item_codes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__chargeItemCodes' */'@/pages/accounting/chargeItemCodes'), loading: LoadingComponent}),
        "access": "charge_item_codes",
        "exact": true
      },
      {
        "path": "/accounting/tax_codes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__taxCodes' */'@/pages/accounting/taxCodes'), loading: LoadingComponent}),
        "access": "charge_item_codes",
        "exact": true
      },
      {
        "path": "/accounting/charge_item_templates",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__chargeItemTemplates' */'@/pages/accounting/chargeItemTemplates'), loading: LoadingComponent}),
        "access": "charge_item_templates",
        "exact": true
      },
      {
        "path": "/accounting/accounts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__accounts' */'@/pages/accounting/accounts'), loading: LoadingComponent}),
        "access": "bank_accounts",
        "exact": true
      },
      {
        "path": "/accounting/ar_ap",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__arapList' */'@/pages/accounting/arapList'), loading: LoadingComponent}),
        "access": "open_invoices",
        "exact": true
      },
      {
        "path": "/accounting/invoices/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__newInvoice' */'@/pages/accounting/newInvoice'), loading: LoadingComponent}),
        "access": "create_invoice",
        "exact": true
      },
      {
        "path": "/accounting/invoices/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__editInvoice' */'@/pages/accounting/editInvoice'), loading: LoadingComponent}),
        "access": "edit_invoice",
        "exact": true
      },
      {
        "path": "/accounting/invoices/:ext_id/pay",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__invoice' */'@/pages/accounting/invoice'), loading: LoadingComponent}),
        "access": "edit_invoice",
        "exact": true
      },
      {
        "path": "/accounting/invoices",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__invoices' */'@/pages/accounting/invoices'), loading: LoadingComponent}),
        "access": "open_invoices",
        "exact": true
      },
      {
        "path": "/accounting/received_invoices",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__receivedInvoices' */'@/pages/accounting/receivedInvoices'), loading: LoadingComponent}),
        "access": "paid_invoices",
        "exact": true
      },
      {
        "path": "/accounting/all_invoices",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__allInvoices' */'@/pages/accounting/allInvoices'), loading: LoadingComponent}),
        "access": "all_invoices",
        "exact": true
      },
      {
        "path": "/accounting/bills/pending/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__pendingBill' */'@/pages/accounting/pendingBill'), loading: LoadingComponent}),
        "access": "chargeItemRouteFilter",
        "exact": true
      },
      {
        "path": "/accounting/bills/pending",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__pendingBills' */'@/pages/accounting/pendingBills'), loading: LoadingComponent}),
        "access": "open_bills",
        "exact": true
      },
      {
        "path": "/accounting/bills/daily_pending",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__dailyPendingBills' */'@/pages/accounting/dailyPendingBills'), loading: LoadingComponent}),
        "access": "daily_bills",
        "exact": true
      },
      {
        "path": "/accounting/bills",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__allBills' */'@/pages/accounting/allBills'), loading: LoadingComponent}),
        "access": "all_bills",
        "exact": true
      },
      {
        "path": "/accounting/bills/paid",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__paidBills' */'@/pages/accounting/paidBills'), loading: LoadingComponent}),
        "access": "paid_bills",
        "exact": true
      },
      {
        "path": "/accounting/bills/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__Bill__newBill' */'@/pages/accounting/Bill/newBill'), loading: LoadingComponent}),
        "access": "create_invoice",
        "exact": true
      },
      {
        "path": "/accounting/bills/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__Bill__editBill' */'@/pages/accounting/Bill/editBill'), loading: LoadingComponent}),
        "access": "edit_invoice",
        "exact": true
      },
      {
        "path": "/accounting/credit_notes/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__newDCNote' */'@/pages/accounting/newDCNote'), loading: LoadingComponent}),
        "access": "create_invoice",
        "exact": true
      },
      {
        "path": "/accounting/credit_notes/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__dcNoteDetails' */'@/pages/accounting/dcNoteDetails'), loading: LoadingComponent}),
        "access": "edit_invoice",
        "exact": true
      },
      {
        "path": "/accounting/debit_notes/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__newDCNote' */'@/pages/accounting/newDCNote'), loading: LoadingComponent}),
        "access": "create_invoice",
        "exact": true
      },
      {
        "path": "/accounting/debit_notes/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__dcNoteDetails' */'@/pages/accounting/dcNoteDetails'), loading: LoadingComponent}),
        "access": "edit_invoice",
        "exact": true
      },
      {
        "path": "/accounting/credit_notes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__creditNotes' */'@/pages/accounting/creditNotes'), loading: LoadingComponent}),
        "access": "all_dc_notes",
        "exact": true
      },
      {
        "path": "/accounting/debit_notes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__debitNotes' */'@/pages/accounting/debitNotes'), loading: LoadingComponent}),
        "access": "all_dc_notes",
        "exact": true
      },
      {
        "path": "/accounting/transactions/incoming_payments/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__transactionDetails' */'@/pages/accounting/transactionDetails'), loading: LoadingComponent}),
        "access": "accountingRouteFilter",
        "exact": true
      },
      {
        "path": "/accounting/transactions/incoming_payments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__pendingCreditTransactions' */'@/pages/accounting/pendingCreditTransactions'), loading: LoadingComponent}),
        "access": "pending_incoming_transactions",
        "exact": true
      },
      {
        "path": "/accounting/transactions/deposits",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__deposits' */'@/pages/accounting/deposits'), loading: LoadingComponent}),
        "access": "all_incoming_transactions",
        "exact": true
      },
      {
        "path": "/accounting/transactions/payments/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__transactionDetails' */'@/pages/accounting/transactionDetails'), loading: LoadingComponent}),
        "access": "accountingRouteFilter",
        "exact": true
      },
      {
        "path": "/accounting/transactions/payments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__payments' */'@/pages/accounting/payments'), loading: LoadingComponent}),
        "access": "all_outgoing_transactions",
        "exact": true
      },
      {
        "path": "/accounting/transactions/bank_reconciliation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__bankReconciliation' */'@/pages/accounting/bankReconciliation'), loading: LoadingComponent}),
        "access": "bank_reconciliation",
        "exact": true
      },
      {
        "path": "/accounting/transactions/all",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__transactions' */'@/pages/accounting/transactions'), loading: LoadingComponent}),
        "access": "accountingRouteFilter",
        "exact": true
      },
      {
        "exact": true,
        "path": "/accounting/transactions",
        "redirect": "/accounting/transactions/incoming_payments"
      },
      {
        "path": "/accounting/statements/my",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__statements' */'@/pages/accounting/statements'), loading: LoadingComponent}),
        "access": "new_statement",
        "exact": true
      },
      {
        "path": "/accounting/statements/open",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__statements' */'@/pages/accounting/statements'), loading: LoadingComponent}),
        "access": "open_statements",
        "exact": true
      },
      {
        "path": "/accounting/statements/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__Statement__newStatement' */'@/pages/accounting/Statement/newStatement'), loading: LoadingComponent}),
        "access": "edit_statement",
        "exact": true
      },
      {
        "path": "/accounting/statements/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__statementDetailsPage' */'@/pages/accounting/statementDetailsPage'), loading: LoadingComponent}),
        "access": "edit_statement",
        "exact": true
      },
      {
        "path": "/accounting/statements",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__statements' */'@/pages/accounting/statements'), loading: LoadingComponent}),
        "access": "all_statements",
        "exact": true
      },
      {
        "path": "/accounting/payment_plans",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__paymentPlans' */'@/pages/accounting/paymentPlans'), loading: LoadingComponent}),
        "access": "all_statements",
        "exact": true
      },
      {
        "path": "/accounting/payment_plans/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__Statement__newPaymentPlan' */'@/pages/accounting/Statement/newPaymentPlan'), loading: LoadingComponent}),
        "access": "new_statement",
        "exact": true
      },
      {
        "path": "/accounting/payment_plans/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__paymentPlanDetailsPage' */'@/pages/accounting/paymentPlanDetailsPage'), loading: LoadingComponent}),
        "access": "edit_statement",
        "exact": true
      },
      {
        "path": "/accounting/journals/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__journalEntry' */'@/pages/accounting/journalEntry'), loading: LoadingComponent}),
        "access": "new_journal_entry",
        "exact": true
      },
      {
        "path": "/accounting/journals/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__journalEntry' */'@/pages/accounting/journalEntry'), loading: LoadingComponent}),
        "access": "general_journals",
        "exact": true
      },
      {
        "path": "/accounting/journals",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__generalJournal' */'@/pages/accounting/generalJournal'), loading: LoadingComponent}),
        "access": "general_journals",
        "exact": true
      },
      {
        "path": "/accounting/year_end_closing",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__yearEndClosing' */'@/pages/accounting/yearEndClosing'), loading: LoadingComponent}),
        "access": "year_end_closing",
        "exact": true
      },
      {
        "path": "/accounting/locks",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__locks' */'@/pages/accounting/locks'), loading: LoadingComponent}),
        "access": "accountingRouteFilter",
        "exact": true
      },
      {
        "path": "/accounting/currency_rates",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__currencyRates' */'@/pages/accounting/currencyRates'), loading: LoadingComponent}),
        "access": "currency_rates",
        "exact": true
      },
      {
        "path": "/accounting/fapiao",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accounting__fapiao__list' */'@/pages/accounting/fapiao/list'), loading: LoadingComponent}),
        "access": "edit_fapiao",
        "exact": true
      },
      {
        "path": "/reports/account_payables_aging",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__accountPayablesAging' */'@/pages/reports/accountPayablesAging'), loading: LoadingComponent}),
        "access": "account_reports_or_aging_report",
        "exact": true
      },
      {
        "path": "/reports/account_receivables_aging",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__accountReceivablesAging' */'@/pages/reports/accountReceivablesAging'), loading: LoadingComponent}),
        "access": "account_reports_or_aging_report",
        "exact": true
      },
      {
        "path": "/reports/bank_statement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__bankStatement' */'@/pages/reports/bankStatement'), loading: LoadingComponent}),
        "access": "account_reports",
        "exact": true
      },
      {
        "path": "/reports/revenue",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__revenue' */'@/pages/reports/revenue'), loading: LoadingComponent}),
        "access": "revenue_report",
        "exact": true
      },
      {
        "path": "/reports/account_balance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__accountBalance' */'@/pages/reports/accountBalance'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/balance_sheet",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__balanceSheet' */'@/pages/reports/balanceSheet'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/income_statement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__incomeStatement' */'@/pages/reports/incomeStatement'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/cash_flow_statement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__cashFlowStatement' */'@/pages/reports/cashFlowStatement'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/gl",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__glReport' */'@/pages/reports/glReport'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/journal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__journalReport' */'@/pages/reports/journalReport'), loading: LoadingComponent}),
        "access": "journal_report",
        "exact": true
      },
      {
        "path": "/reports/shipments_chart",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__shipments' */'@/pages/reports/shipments'), loading: LoadingComponent}),
        "access": "shipment_report",
        "exact": true
      },
      {
        "path": "/reports/shipments_report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__shipmentReport' */'@/pages/reports/shipmentReport'), loading: LoadingComponent}),
        "access": "shipment_report",
        "exact": true
      },
      {
        "path": "/reports/shipment_sales_report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__shipmentSalesReport' */'@/pages/reports/shipmentSalesReport'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/reports/shipment_milestone_report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__shipmentsMilestoneReport' */'@/pages/reports/shipmentsMilestoneReport'), loading: LoadingComponent}),
        "access": "shipment_report",
        "exact": true
      },
      {
        "path": "/reports/employee",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__employee' */'@/pages/reports/employee'), loading: LoadingComponent}),
        "access": "employee_report",
        "exact": true
      },
      {
        "path": "/reports/shipments_pnl/:type",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__shipmentsPNL' */'@/pages/reports/shipmentsPNL'), loading: LoadingComponent}),
        "access": "shipment_pl_report",
        "exact": true
      },
      {
        "path": "/reports/shipments_pnl",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__shipmentsPNL' */'@/pages/reports/shipmentsPNL'), loading: LoadingComponent}),
        "access": "shipment_pl_report",
        "exact": true
      },
      {
        "path": "/reports/daily_cash",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__dailyCash' */'@/pages/reports/dailyCash'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/current_account",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__currentAccount' */'@/pages/reports/currentAccount'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/reports/tax_report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__taxReport' */'@/pages/reports/taxReport'), loading: LoadingComponent}),
        "access": "company_statements",
        "exact": true
      },
      {
        "path": "/approvals",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__approval__list' */'@/pages/approval/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quotations/:ext_id/:tab(accounting|info|documents|status)?",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__quotation__detail' */'@/pages/quotation/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quotations",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__quotation__list' */'@/pages/quotation/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/qtoshipments/:ext_id/:tab(accounting|info|documents|status)?",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__qtoshipment__detail' */'@/pages/qtoshipment/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/qtoshipments",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__qtoshipment__list' */'@/pages/qtoshipment/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/tasks",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tasks__index' */'@/pages/tasks/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/isfs/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ISF' */'@/pages/ISF'), loading: LoadingComponent}),
        "access": "new_isf",
        "exact": true
      },
      {
        "path": "/isfs/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ISF__list' */'@/pages/ISF/list'), loading: LoadingComponent}),
        "access": "access_isf",
        "exact": true
      },
      {
        "path": "/isfs/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ISF__list' */'@/pages/ISF/list'), loading: LoadingComponent}),
        "access": "access_isf",
        "exact": true
      },
      {
        "path": "/isfs/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ISF' */'@/pages/ISF'), loading: LoadingComponent}),
        "access": "access_isf",
        "exact": true
      },
      {
        "path": "/user/profile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__profile' */'@/pages/user/profile'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/aci/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ACI' */'@/pages/ACI'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/aci/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ACI__List' */'@/pages/ACI/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/aci/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ACI' */'@/pages/ACI'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/aci/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ACI__List' */'@/pages/ACI/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ams/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS' */'@/pages/AMS'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ams/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS__AMSList' */'@/pages/AMS/AMSList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ams/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS' */'@/pages/AMS'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ams/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS__AMSList' */'@/pages/AMS/AMSList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/(ams_bol|aci_bol)/new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS__billOfLading' */'@/pages/AMS/billOfLading'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/(ams_bol|aci_bol)/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS__billOfLadingList' */'@/pages/AMS/billOfLadingList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/(ams_bol|aci_bol)/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS__billOfLading' */'@/pages/AMS/billOfLading'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/(ams_bol|aci_bol)/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AMS__billOfLadingList' */'@/pages/AMS/billOfLadingList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__home' */'@/pages/dashboard/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ai/inbox",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ai__inboxList' */'@/pages/ai/inboxList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ai/inbox/:ext_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ai__inboxDetail' */'@/pages/ai/inboxDetail'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
