import React, { createContext, useContext, useCallback } from 'react';
import { Excel } from 'antd-table-saveas-excel';
import { filter } from 'lodash';
import { message } from 'antd';
import { ProColumns } from '@ant-design/pro-table';

interface DownloadContextType {
  downloadExcel: <T = any>(params: {
    columns: ProColumns<T>[];
    getDataFn: () => Promise<T[]>;
    filename: string;
  }) => Promise<void>;
}

const DownloadContext = createContext<DownloadContextType | null>(null);

export const useDownload = () => {
  const context = useContext(DownloadContext);
  if (!context) {
    throw new Error('useDownload must be used within a DownloadProvider');
  }
  return context;
};

export const DownloadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const downloadExcel = useCallback(
    async <T extends unknown>({
      columns,
      getDataFn,
      filename,
    }: {
      columns: ProColumns<T>[];
      getDataFn: () => Promise<T[]>;
      filename: string;
    }) => {
      try {
        // 获取数据
        const data = await getDataFn();

        if (!data.length) {
          message.warning('没有数据可供下载');
          return;
        }

        // 清理 columns 数据，只保留必要的字段
        const cleanColumns = columns.map(col => ({
          dataIndex: col.dataIndex,
          title: typeof col.title === 'string' ? col.title : '',
          hideInTable: col.hideInTable,
        }));

        // 生成Excel
        const excel = new Excel();
        excel
          .addSheet('Sheet 1')
          .addColumns(filter(cleanColumns, c => !c.hideInTable))
          .addDataSource(data, {
            str2Percent: true,
          })
          .saveAs(filename);
      } catch (error) {
        console.error('Download error:', error);
        message.error('下载失败');
      }
    },
    [],
  );

  return <DownloadContext.Provider value={{ downloadExcel }}>{children}</DownloadContext.Provider>;
};

export default DownloadProvider;
