import { RequestConfig } from 'umi';
import { errorHandler, buildAuthorization } from '@/utils/request';
import { getUserInfo } from '@/services/user';
import qs from 'qs';
import { getColors } from './services/colors';
import { getTaxCodes } from './services/accounting';
import { getPdfTemplates } from './services/pdfs';
import { ConfigProvider } from 'antd';
import React from 'react';
import { DownloadProvider } from './components/DownloadProvider';

const errorLog = console.error;
console.error = (...rest: (string | string[])[]) => {
  if (rest?.[0] && rest?.[0].indexOf?.('[sagaEffects.put]') === -1) {
    errorLog.call(console, ...rest);
  }
};

// get the subdomain from window.location.host
const subdomain = window.location.host.split('.')[0];
// replace subdomain in BASE_URL with the subdomain from window.location.host
const baseURL =
  BASE_URL.indexOf('localhost') === -1
    ? BASE_URL.replace('//api.', `//${subdomain}-api.`)
    : BASE_URL;
export const request: RequestConfig = {
  errorHandler,
  credentials: 'include',
  prefix: baseURL,
  // timeout: 1000, // ms
  errorConfig: {},
  middlewares: [],
  requestInterceptors: [
    (url, options) => {
      if (options && options.headers) {
        const authHeader = buildAuthorization();
        if (authHeader) {
          options.headers['Authorization'] = authHeader;
        } else {
          const searchParams = new URLSearchParams(window.location.search);
          if (searchParams.get('access_token')) {
            options.headers['Authorization'] = `Bearer ${searchParams.get('access_token')}`;
          }
        }
        if (!options.headers['x-smartforwarder-appid'] && SF_APP_ID) {
          options.headers['x-smartforwarder-appid'] = SF_APP_ID;
        }
        options.headers['Accept-Language'] = localStorage.getItem('umi_locale') || 'en-US';
      }
      return { url, options };
    },
  ],
  responseInterceptors: [],
};
export const dva = {
  config: {
    namespacePrefixWarning: false,
    onError(err: ErrorEvent) {
      err.preventDefault();

      console.error(err.message);
    },
  },
};
export async function getInitialState() {
  if (
    window.location.pathname.startsWith('/documents') ||
    window.location.pathname.startsWith('/login') ||
    window.location.pathname.startsWith('/public') ||
    window.location.pathname.startsWith('/forgot_password') ||
    window.location.pathname.startsWith('/reset_password')
  ) {
    return null;
  }
  const umiLocale = localStorage.getItem('umi_locale');
  const data = await getUserInfo();
  const colors = await getColors();
  const taxCodes = await getTaxCodes();
  const pdfTemplates = await getPdfTemplates();
  data.umiLocale = umiLocale;
  data.colors = colors.data;
  data.taxCodes = taxCodes.data;
  data.pdfTemplates = pdfTemplates.data;
  if (data.statusCode === 401) {
    return null;
  }
  return data;
}

export const locale = {
  getLocale() {
    const umiLocale = localStorage.getItem('umi_locale') || 'en-US';
    const { search } = window.location;
    const { locale } = qs.parse(search, { ignoreQueryPrefix: true });
    if (locale) {
      return locale;
    }
    return umiLocale;
  },
};

const ConfigProviderWrapper = (props: any) => {
  return (
    <ConfigProvider
      space={{
        size: 'small',
      }}
      form={{
        validateMessages: {},
      }}
      componentSize="small"
    >
      <DownloadProvider>{props.children}</DownloadProvider>
    </ConfigProvider>
  );
};

export function rootContainer(container: any) {
  return React.createElement(ConfigProviderWrapper, null, container);
}
