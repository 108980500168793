export default {
  'inbox.delete.success': 'Item deleted successfully.',
  'inbox.field.description': 'Description',
  'inbox.field.title': 'Title',
  'inbox.field.contact': 'Contact',
  'inbox.field.message': 'Message',
  'inbox.title.create': 'Create item',
  'inbox.field.accepted_status': 'Accepted',
  'inbox.status.accepted': 'Accepted',
  'inbox.field.status': 'Status',
};
