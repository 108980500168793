export default {
  'contact.list_title.contacts': 'Contacts',
  'contact.field.name': 'Name',
  'contact.field.username': 'User Name',
  'contact.field.local_name': 'Print Name',
  'contact.field.native_name': 'Local Reg. Name',
  'contact.field.tags': 'Tags',
  'contact.field.account_group': 'Account Group',
  'contact.field.emails': 'Email(s)',
  'contact.field.contact': 'Contact',
  'contact.field.name.placeholder': 'Please enter name here',
  'contact.field.short_name': 'Short Name',
  'contact.field.types': 'Types',
  'contact.field.type': 'Type',

  'contact.field.type.shipper': 'Shipper',
  'contact.field.type.consignee': 'Consignee',
  'contact.field.type.carrier': 'Carrier',
  'contact.field.type.notify': 'Notify',
  'contact.field.type.agent': 'Agent',
  'contact.field.type.location': 'Location',
  'contact.field.type.broker': 'Broker',
  'contact.field.type.trucker': 'Trucker',
  'contact.field.type.bank': 'Bank',
  'contact.field.type.other': 'Other',
  'contact.field.type.customer': 'Customer',
  'contact.field.type.coloader': 'Coloader',
  'contact.field.type.airline': 'Airline',
  'contact.field.type.employee': 'Employee',

  'contact.field.scac': 'SCAC',
  'contact.field.firms_code': 'Firms Code',
  'contact.field.notes': 'Notes',
  'contact.field.import_no': 'Import No.',
  'contact.field.address': 'Address',
  'contact.field.manual_print_address': 'Print Address',
  'contact.field.phone': 'Phone',
  'contact.field.city': 'City',
  'contact.field.state': 'State',
  'contact.field.state.format': 'State code only allowed 2 digits',
  'contact.field.zip': 'Zip Code',
  'contact.field.country': 'Country',
  'contact.field.fax': 'Fax',
  'contact.field.accounting_emails': 'Accounting Email(s)',
  'contact.field.outstanding_balance': 'Outstanding Balance',
  'contact.field.credit_limit': 'Credit Limit',
  'contact.field.auto_send_local_statement': 'Auto send local statement',
  'contact.field.enable_tracking': 'Enable Tracking',
  'contact.field.subject': 'Subject',
  'contact.field.content': 'Content',
  'contact.field.created_by': 'Created By',
  'contact.field.created_at': 'Created At',
  'contact.field.updated_at': 'Updated At',
  'contact.field.type.select.placeholder': 'Please Select type',
  'contact.field.bank_name': 'Bank Name',
  'contact.field.bank_account_no': 'Account No.',
  'contact.field.bank_currency': 'Currency',
  'contact.field.usci_no': 'Tax ID / USCI No.',
  'contact.field.payment_type': 'Payment Type',
  'contact.field.credit_term_type': 'Credit Term ',
  'contact.field.credit_days': 'Credit Days',
  'contact.field.billing_address': 'Billing Address',
  'contact.field.title': 'Title',
  'contact.field.rep': 'Rep',
  'contact.tab.documents': 'Documents',
  'contact.tab.accounting': 'Accounting Settings',
  'contact.tab.memos': 'Memos',
  'contact.tab.status': 'Status',
  'contact.tab.info': 'Info',
  'contact.tab.emails': 'Emails',
  'contact.tab.emails.sent_emails': 'Sent Emails',
  'contact.tab.emails.received_items': 'Received Items',
  'contact.add': 'Add Contact',
  'contact.memo.add': 'Add Memo',
  'contact.memo.edit': 'Edit Memo',
  'contact.memo.delete': 'Delete Memo',
  'contact.memo.add.success': 'Successfully added Memo',
  'contact.memo.delete.success': 'Successfully deleted Memo',
  'contact.memo.delete.confirm': 'Are you sure to delete this memo?',
  'contact.memo.same_name.notice': 'You can not use the same name as the existing contact!',
  'contact.memo.empty': 'No Memo',
  'contact.create.success': 'Successfully added new contact',
  'contact.update.success': 'Successfully updated contact',
  'contact.delete.success': 'Successfully deleted contact',
  'contact.disable.success': 'Successfully disabled contact',
  'contact.enable.success': 'Successfully enabled contact',
  'contact.send.success': 'Successfully sent statement',
  'contact.not_match': 'Type does not match',
  'contact.type_to_search': 'Type to search',
  'contact.empty': 'No contact found',
  'contact.add_new_contact': 'Add A New Contact',
  'contact.document.field.name_document_no': 'Name/Document No.',
  'contact.document.field.type': 'Type',
  'contact.document.field.updated_at': 'updated_at',
  'contact.document.field.created_by': 'Created By',
  'contact.document.field.type.required': 'Please select document type.',
  'contact.document.field.type.placeholder': 'Select document type',
  'contact.document.field.description': 'Description',
  'contact.document.field.file': 'File',
  'contact.document.field.file.title': 'Click or drag file to this area to upload',
  'contact.document.field.notice': 'only members from your team can see it',
  'contact.document.field.name_document_no.placeholder': 'Enter name/document no.',
  'contact.document.field.name_document_no.required': 'Please enter name/document no.',

  'contact.delete.confirm': 'Are you sure you want to delete this contact?',
  'contact.document.delete.success': 'Successfully Deleted Document',
  'contact.document.update.success': 'Successfully Updated Document',
  'contact.document.empty': 'No Documents',
  'contact.disable.confirm': 'Are you sure you want to disable this contact?',
  'contact.enable.confirm': 'Are you sure you want to enable this contact?',
  'contact.proceed.confirm.title': 'Over Due. Do you want to proceed?',
  'contact.shipment.proceed.confirm.title':
    'This contact is not in your shipment. Are you sure to proceed?',
  'contact.proceed.confirm.content': 'Current Due Amount {total}',
  'contact.field.contact_type.by_monthly': 'By Monthly',
  'contact.field.contact_type.by_billing': 'By Billing',
  'contact.button.copy_from_address': 'Copy from Address',
  'contact.button.copy_from_email': 'Copy from Email',
  'contact.message.already_exists_with_name': 'Contact already exists with similar name',
  'contact.message.proceed_anyway': 'Are you sure you want to proceed anyway?',
  'contact.action.tags': 'Account Group',
  'contact.action.merge': 'Merge',
  'contact.merge.title': 'Merge Contact',
  'contact.merge.source': 'Source',
  'contact.merge.target': 'Target',
  'contact.merge.notes.merge_data':
    'Merge Contacts merges related entities (Shipments, Invoices, etc.) from the source contact to the target contact.',
  'contact.merge.notes.disable_source': 'The source contact will be disabled after the merge.',
  'contact.merge.notes.no_undo': 'This action cannot be undone.',
  'contact.merge.options.incl_contacts': 'Include Contact Persons',
  'contact.merge.options.incl_documents': 'Include Documents',
  'contact.merge.options.incl_memos': 'Include Memos',
  'contact.merge.options.incl_trade_parties': 'Include Trade Parties',
  'contact.action.switch_to_regular': 'Switch to Regular Contact',
  'contact.confirm.switch_to_regular':
    'Are you sure you want to switch these temporary contacts to regular contacts?',
};
